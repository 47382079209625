<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="customerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching customer data
      </h4>
      <div class="alert-body">
        No customer found with this id. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'customers-list'}"
        >
          Customers List
        </b-link>
        for other customers.
      </div>
    </b-alert>

    <template v-if="customerData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <customer-info-card
            :customer-data="customerData"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="6"
          md="6"
        >
          <!-- <vehicle-check-history-card
            :vehicle-id="customerData.id"
          /> -->
        </b-col>

      </b-row>
      </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import customerStoreModule from '@/views/customer/customerStoreModule'
import customerInfoCard from '@/views/customer/customers-view/CustomerInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    customerInfoCard,

  },
  methods: {
    handleCustomerDataUpdate(data) {
      this.customerData = data
    },
  },
  setup() {
    const customerData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-customer/fetchCustomer', { id: router.currentRoute.params.id })
      .then(response => {
        customerData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          customerData.value = undefined
        }
      })
    return {
      customerData,
    }
  },
}
</script>

<style>

</style>
