<template>
  <div>
    <customer-edit-info
      :is-edit-customer-sidebar-active.sync="isEditCustomerSidebarActive"
      :customer-data="viewCustomerData"
      @updateCustomerInfo="customerViewInfoUpdate"
    />
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="viewCustomerData.avatar"
              :text="avatarText(viewCustomerData.fullName)"
              variant="light-primary"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ viewCustomerData.fullName }}
                </h4>
                <span class="card-text">{{ viewCustomerData.company === true ? "Business" : "Private" }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-dropdown
                  id="dropdown-1"
                  variant="outline-primary"
                >
                  <template #button-content>
                    <b-spinner
                      v-if="isLoading"
                      small
                      class="mr-50"
                    />
                    <span>Actions</span>
                  </template>
                  <b-dropdown-item
                    @click="isEditCustomerSidebarActive = true"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="ClipboardIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ viewCustomerData.jobCount }}
                </h5>
                <small>Jobs</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="AtSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Email</span>
              </th>
              <td class="pb-50">
                {{ viewCustomerData.email }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Phone Number</span>
              </th>
              <td class="pb-50">
                {{ viewCustomerData.phoneNumber ? viewCustomerData.phoneNumber : 'No phone number added' }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Postal Code</span>
              </th>
              <td class="pb-50">
                {{ viewCustomerData.postalCode ? viewCustomerData.postalCode : 'No postal code added' }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address</span>
              </th>
              <td class="pb-50">
                {{ viewCustomerData.streetAddress ? viewCustomerData.streetAddress : 'No address added' }}
              </td>
            </tr>

            <tr v-if="viewCustomerData.company === true">
              <th class="pb-50">
                <feather-icon
                  icon="BriefcaseIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Company Name</span>
              </th>
              <td class="pb-50">
                {{ viewCustomerData.companyName }}
              </td>
            </tr>
            <tr v-if="viewCustomerData.company === true">
              <th>
                <feather-icon
                  icon="BriefcaseIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">VAT Number</span>
              </th>
              <td>
                {{ viewCustomerData.companyVatNumber ? viewCustomerData.companyVatNumber : 'No VAT number added' }}
              </td>
            </tr>

          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-card-text>
            <b-row
              align-h="end"
              class="text-muted w-100 mt-2 mx-0"
            >
              <b-col
                class="text-right"
              >
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Added: {{ toHumanDate(viewCustomerData.createdAt) }}</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import toHumanDate from '@/libs/utils/general/dates'
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

import customerEditInfo from '@/views/customer/customers-edit/CustomerEditInfo.vue'

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    customerEditInfo,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewCustomerData: this.$props.customerData,
      isLoading: false,
    }
  },
  methods: {
    customerViewInfoUpdate(data) {
      this.viewCustomerData = data
    },
  },
  setup() {
    const isEditCustomerSidebarActive = ref(false)

    return {
      avatarText,
      toHumanDate,
      isEditCustomerSidebarActive,
    }
  },
}
</script>

<style>

</style>
